














































































































import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import RevenueEventRequestModel from "@/settings/models/revenue-events/RevenueEventRequestModel";
import {
  AppSection,
  Dictionary,
  DictionaryType,
  REVENUE_EVENTS_DICTIONARIES,
} from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component
export default class RevenueEventsForm extends Vue {
  @Prop() value!: RevenueEventRequestModel;
  @Prop({ default: "" }) title!: string;
  @Prop({ default: false }) isEdit!: boolean;
  @Ref("form") readonly form!: VueForm;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  localValue: RevenueEventRequestModel = RevenueEventRequestModel.ofSource(
    this.value
  );
  isValid = false;
  search: string | null = null;
  eventNames: Array<Dictionary> = [];

  get applicationId(): string {
    return this.$route.params.id;
  }

  get requiredRuleForEvent() {
    return this.isEventDisabled
      ? []
      : [ValidUtil.required(this.$lang("validation.required"))];
  }

  get isEventDisabled(): boolean {
    return ["iaps", "subscription"].includes(this.localValue.type);
  }

  get tokenErrorMessages(): Array<string> {
    return this.isTokenUnique ? [] : [this.$lang("shared.errors.notUnique")];
  }

  get isSavingInProgress() {
    return this.$store.state.revenueEventsStore.isSavingInProgress;
  }

  get isTokenUnique() {
    return this.$store.state.revenueEventsStore.isTokenUnique;
  }

  get types(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[DictionaryType.REVENUES].values;
  }

  get events(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[DictionaryType.EVENTS_EXTENDED]
      .values;
  }

  get appVersions(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[DictionaryType.APP_VERSIONS]
      .values;
  }

  get appVersionsFrom() {
    return this.appVersions.map((version: Dictionary) => {
      if (
        !!this.localValue?.appVersionTo &&
        !this.localValue?.appVersionFrom &&
        version.value > this.localValue?.appVersionTo
      ) {
        return { ...version, disabled: true };
      } else if (
        !!this.localValue?.appVersionTo &&
        !!this.localValue?.appVersionFrom &&
        version.value > this.localValue?.appVersionTo
      ) {
        return { ...version, disabled: true };
      }

      return version;
    });
  }

  get appVersionsTo() {
    return this.appVersions.map((version: Dictionary) => {
      if (
        this.localValue &&
        !!this.localValue.appVersionFrom &&
        version.value < this.localValue.appVersionFrom
      ) {
        return { ...version, disabled: true };
      }

      return version;
    });
  }

  @Watch("localValue", { deep: true })
  private watchLocalValue() {
    this.emitChangedValue();
  }

  @Watch("localValue.type")
  private watchType() {
    if (this.isEventDisabled && this.localValue.event) {
      this.localValue.event = "";
    }
  }

  @Watch("applicationId", { immediate: true })
  private watchApplicationId(applicationId: string) {
    this.loadDictionaries();
    this.localValue.applicationId = applicationId;
  }

  async loadDictionaries() {
    await this.$store.dispatch("loadDictionaries", {
      app: this.applicationId,
      dictionaryTypes: REVENUE_EVENTS_DICTIONARIES,
    });
    this.eventNames = this.$store.state.dictionaryStore[
      DictionaryType.EVENT_NAMES
    ].values.map(
      ({ value, name, parentValue }: Dictionary) =>
        new Dictionary(value, `${name} - ${value}`, parentValue)
    );
  }

  handleInputCombobox(newValue: Dictionary | string) {
    if (typeof newValue === "string") {
      this.localValue.token = newValue;
      this.eventNames.push(new Dictionary(newValue, newValue, null));
    } else {
      this.localValue.token = newValue.value;
    }

    this.$store.dispatch("checkIsTokenUnique", this.localValue);
  }

  handleCancel() {
    this.$router.push({
      name: AppSection.AD_REVENUE_EVENTS,
      params: { id: this.applicationId },
    });
  }

  handleSave() {
    if (!this.form.validate()) {
      return;
    }

    this.emitSave();
  }

  @Emit("save") emitSave(): RevenueEventRequestModel {
    return this.localValue;
  }

  @Emit("input") emitChangedValue(): RevenueEventRequestModel {
    return this.localValue;
  }
}
